<script setup lang="ts">
import { AppButton } from '@mogelijk-technologies/ui-library';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const links = [
  {
    href: 'https://www.mogelijk.nl/privacy/',
    text: 'privacyverklaring',
  },
  {
    href: 'https://www.mogelijk.nl/cookieverklaring/',
    text: 'cookieverklaring',
  },
  {
    href: 'https://www.mogelijk.nl/disclaimer/',
    text: 'disclaimer',
  },
  {
    href: 'https://www.mogelijk.nl/voorwaarden/',
    text: 'voorwaarden',
  },
];

const goToUrl = (url: string) => {
  window.open(url, '_blank');
};

</script>

<template>
  <footer
    class="flex w-full max-w-screen flex-col p-4 text-center text-blue-700 sm:mx-auto sm:flex-row sm:justify-between sm:text-start 3xl:max-w-screen-2xl"
  >
    <div class="mb-2 flex flex-wrap">
      <span v-for="(link, index) in links" :key="link.text" class="flex">
        <span v-if="index > 0">
          <FontAwesomeIcon :icon="['fas', 'circle-small']" size="xs" class="mx-3" />
        </span>
        <AppButton :data-test-id="`${link.text}-footer-link`" type="tertiary" class="!text-base" @click="goToUrl(link.href)">
          {{ link.text }}
        </AppButton>
      </span>
    </div>
    <span>
      Met <FontAwesomeIcon :icon="['fas', 'heart']" /> door Mogelijk
      Technologies
    </span>
  </footer>
</template>
